import {
  CommentOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Divider, Flex, Space, Typography } from "antd";
import { QueueContext, useQuickJoinQueue } from "api/queue";
import { SimpleCard } from "component/PageCard";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PlanContext, PlanType } from "./api/plan";
import { Container, DefaultHeader, ProfileAction } from "./component/Container";
import { PlanCard } from "./component/Plan";

import { useLogPage } from "api/analytics";
import { FriendsContext } from "api/friends";
import { SmallQueueCard } from "component/Queue";
import Friends from "./images/landing.png";
dayjs.extend(localizedFormat);

const Home = () => {
  const { plan } = useContext(PlanContext);
  const { queue } = useContext(QueueContext);
  const navigate = useNavigate();
  const { data: quickJoinQueue } = useQuickJoinQueue();
  const friends = useContext(FriendsContext);
  useLogPage("home");

  if (!queue && (!plan || plan.length === 0)) {
    return <Navigate to="/createQueue" />;
  }

  let content;
  let footer = null;
  const queueContent = queue ? (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={2}>Waiting for others...</Typography.Title>
      {queue.map((q) => (
        <SmallQueueCard queue={q} />
      ))}
      {quickJoinQueue?.map((q) => (
        <Badge
          count={<Avatar size="large" src={friends[q.creator]?.profilePic} />}
          style={{
            top: 0,
            left: 0,
            transform: "translate(-15%, -15%)",
          }}
        >
          <SmallQueueCard queue={q} />
        </Badge>
      ))}
      <Button
        type="primary"
        block
        size="large"
        onClick={() => navigate("/createQueue")}
        icon={<PlusCircleOutlined />}
      >
        Select more activities
      </Button>
    </Space>
  ) : null;

  const planContent =
    plan?.length && plan.length > 0 ? (
      <Flex vertical gap={5} align="center">
        <Typography.Title level={2}>Your matched group</Typography.Title>
        {plan.map((q) => (
          <PlanCard plan={q as PlanType} key={q?.id} />
        ))}
      </Flex>
    ) : (
      <DidYouKnow />
    );
  content = (
    <Space direction="vertical">
      {queueContent}
      {queueContent ? <Divider /> : null}
      {planContent}
    </Space>
  );
  if (!queue) {
    if (planContent) {
      footer = <HomePageFooterButton text="Get a new group!" />;
    } else {
      content = null;
      footer = <HomePageFooterButton text="Get started" />;
    }
  } else {
    footer = null;
  }
  if (!content) {
    content = <IntroCards />;
  }

  return Container({
    header: <DefaultHeader action={<ProfileAction />} />,
    content: (
      <Flex
        align="stretch"
        justify="space-between"
        style={{ height: "100%" }}
        vertical
      >
        {content}
        <Button
          size="small"
          type="link"
          onClick={() => navigate("/feedback")}
          icon={<CommentOutlined />}
        >
          Give us feedback!
        </Button>
      </Flex>
    ),
    footer: footer,
  });
};

export default Home;

// TODO: Remove or include

const IntroCards = ({ children }: React.PropsWithChildren) => {
  /*
  const { profile } = useContext(UserContext);
  const share = useShare();
    if (!profile.verified) {
    const shareProfile = () => {
      const url = "https://jigsaur.com/profile/" + auth.currentUser?.uid;
      const text = `Add me on Jigsaur! My profile is at ${url}`;
      share({ url, text });
    };
      return (
        <SimpleCard
        title="Get verified"
        subtitle={
          <>
            <Typography.Paragraph>
              Share your profile with an
              <strong>existing Jigsaur verified</strong> user who knows and
              trusts you. This chain of trust helps build a strong and safe
              community for everyone.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Once you've been verified, you'll unlock the power to verify your
              own friends!{" "}
            </Typography.Paragraph>
          </>
        }
      >
        <Button icon={<UserAddOutlined />} onClick={shareProfile}>
          Share my profile
        </Button>
      </SimpleCard>
    );
  }
   */
  return (
    <Flex align="center" flex={1}>
      <SimpleCard
        title="Discover Your Next Adventure"
        subtitle=""
        src={Friends}
      >
        <Typography.Text>
          Once you join, our system uses an algorithm to match you with a few
          other individuals who are interested in the same activity to
          participate together.
        </Typography.Text>
      </SimpleCard>
    </Flex>
  );
};

const HomePageFooterButton = ({
  text,
  disabled,
}: {
  text: string;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Button
      type="primary"
      block
      shape="round"
      size="large"
      onClick={() => navigate("/createQueue")}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
const DidYouKnow = () => (
  <Space direction="vertical" align="center">
    <InfoCircleOutlined />
    <Typography.Text strong style={{ textAlign: "center" }}>
      {" "}
      Did you know?{" "}
    </Typography.Text>
    <Typography.Paragraph style={{ textAlign: "center" }}>
      People who engage in more face-to-face communication tend to report higher
      levels of social satisfaction
    </Typography.Paragraph>
    <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
      Haythornthwaite, C. A. (2005). Journal of Communication, 55(2), 246-263.
    </Typography.Paragraph>
  </Space>
);

import { Card, Image, Typography } from "antd";
import { Container, DefaultHeader } from "component/Container";
import sydney from "images/sydney.png";

const Blog = ({ children }: React.PropsWithChildren) => {
  return (
    <Container
      header={<DefaultHeader />}
      content={
        <Card>
          <Typography.Title level={2}>
            How to make new friends in a new city
          </Typography.Title>
          <Typography.Title level={5} type="secondary">
            Moving cities and saying goodbye to your old friends can feel
            impossible. Here are 10 tips for making new friends when moving to a
            new city or town.
          </Typography.Title>
          <Typography.Text strong>Written by JunYang Sim</Typography.Text>
          <Typography.Paragraph>
            Moving house is regularly cited as one of life’s most stressful
            events, right up there with divorce and the death of a loved one.
            Just moving down the road can be taxing. Change is change, and while
            human beings are incredible at adapting to it, that doesn’t mean we
            have to love it.
          </Typography.Paragraph>
          <Image
            width={"100%"}
            src={sydney}
            preview={false}
            style={{ borderRadius: 50 }}
          />
          <Typography.Paragraph>
            If you’re relocating to a whole new city or town, one of your
            worries might be about moving away from your friends. Arriving
            fresh-faced in a new place is exciting – all those bars and
            restaurants to try! – but the prospect of saying goodbye to old pals
            and trying to make new ones can feel a little daunting.
          </Typography.Paragraph>
          Popular culture is saturated with stories about how to meet the love
          of your life. There are entire television shows dedicated to it, whole
          industries built on helping people meet their soul mate. Meeting new
          friends is no less important, but because it doesn’t get the same air
          time, the process can feel less familiar. Where do you even begin?
          Here are 10 tips for making new friends when you move to a new city or
          town.
        </Card>
      }
      footer={null}
    />
  );
};

export default Blog;

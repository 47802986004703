import { DeleteOutlined } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Flex, Form, Input, Table } from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useQuerySnapshot } from "api/firestore";
import { UserId } from "api/user";
import { dateArrayToString } from "api/utils";
import { getDoc } from "firebase/firestore";
import { useContext, useMemo } from "react";
import { FriendsContext } from "../../api/friends";
import { createPlan, PlanType } from "../../api/plan";
import {
  getAllQueueCollection,
  leaveOrDeleteQueueWithUIConfirmation,
  QueueType,
} from "../../api/queue";
import { QueueTypeInput } from "../../component/Form/QueueInput";
import { EasyTag } from "./EasyTag";

const AdminPage = () => {
  const q = useMemo(() => getAllQueueCollection(), []);
  const friends = useContext(FriendsContext);
  const { data } = useQuerySnapshot(q) as { data: QueueType[] };
  const queue = (data || []).map((q, index) => ({
    uid: q.creator,
    ...friends[q.creator],
    ...q,
    id: q.ref?.id,
  }));

  const [form] = useForm();

  return (
    <Flex vertical>
      <Form
        form={form}
        onFinish={(data) => {
          const plan = formToPlanType(form);
          createPlan(plan).then((p) => {
            getDoc(p).then((x) => {
              alert(JSON.stringify(x.data(), null, 2));
            });
          });
        }}
      >
        <Table
          rowKey="id"
          rowSelection={{
            type: "checkbox",
            onChange(selectedRowKeys, selectedRows, info) {
              const participants: UserId[] = [];
              selectedRows.forEach((queue) => {
                participants.push(queue.creator);
                queue.participants?.forEach((p) => participants.push(p));
              });
              form.setFieldValue("participants", participants);
              form.setFieldValue(
                "queueSelected",
                selectedRows.map((p) => p.ref),
              );
            },
          }}
          columns={[
            {
              title: "id",
              key: "id",
              dataIndex: "id",
            },
            {
              title: "Name",
              key: "preferredName",
              dataIndex: "preferredName",
            },
            {
              title: "location",
              key: "location",
              dataIndex: "location",
              sorter: (a, b) => a.activityType.localeCompare(b.activityType),
              render: (x, { location = "" }) => (
                <span>
                  <EasyTag tag={location} />
                </span>
              ),
            },
            {
              title: "participants",
              key: "participants",
              dataIndex: "participants",

              render: (x, { participants = [] }) => (
                <span>
                  {participants.map((i) => (
                    <EasyTag tag={i} />
                  ))}
                </span>
              ),
            },
            {
              title: "interests",
              key: "interests",
              dataIndex: "interests",

              render: (x, { interests = {} }) => (
                <span>
                  {Object.keys(interests).map((i) => (
                    <EasyTag tag={i} />
                  ))}
                </span>
              ),
            },
            {
              title: "activityType",
              key: "activityType",
              dataIndex: "activityType",
              sorter: (a, b) => a.activityType.localeCompare(b.activityType),
            },
            {
              title: "Timeslot",
              key: "preferredDate",
              dataIndex: "preferredDate",
              render: (x, { preferredDate }) => (
                <span>{dateArrayToString(preferredDate)}</span>
              ),
            },
            {
              title: "timeCreated",
              key: "timeCreated",
              dataIndex: "timeCreated",
              render: (x, { timeCreated }) => (
                <span>{timeCreated?.format("lll")}</span>
              ),
              sorter: (a, b) => a.timeCreated.diff(b.timeCreated),
            },
            {
              title: "Delete",
              key: "delete",
              render: (_, record) => (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => leaveOrDeleteQueueWithUIConfirmation(record)}
                />
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <pre style={{ margin: 0 }}>
                  {JSON.stringify(
                    (record as any).additionalQuestions,
                    null,
                    2,
                  ) || ""}
                </pre>

                <Collapse
                  items={[
                    {
                      key: "1",
                      label: "Full profile",
                      children: (
                        <pre style={{ margin: 0 }}>
                          {JSON.stringify(record as any, null, 2) || ""}
                        </pre>
                      ),
                    },
                  ]}
                />
              </>
            ),
            rowExpandable: (record) => true,
          }}
          dataSource={queue}
        />
        <QueueTypeInput />
        <DateTimeInput />
        <DescriptionInput />
        <Form.Item shouldUpdate noStyle>
          {() => {
            return <pre>{JSON.stringify(formToPlanType(form), null, 2)}</pre>;
          }}
        </Form.Item>
        <Button htmlType="submit">Create Plan</Button>
      </Form>
    </Flex>
  );
};
const formToPlanType = (form: FormInstance) => {
  const data = form.getFieldsValue(true);
  const plan: PlanType = {
    canceledParticipants: [],
    confirmedParticipants: [],
    participants: data.participants,
    activity: data.activityType,
    description: data.description,
    // time: data.preferredTime,
    date: data.date,
  };
  return plan;
};
/* 
 * TODO: Complete notifications
export function sendNotificationViaEmail(plan: Omit<PlanType, "id" | "join" | "time">) {
  return addDoc(collection(db, "mail"), {
    sendGrid: {
      dynamicTemplateData: { name: plan, group: "hello" },
    },
    to: ["hi"],
  } as QueuePayload);
}
*/
export function DateTimeInput() {
  return (
    <Form.Item<PlanType>
      label="Preferred Date"
      help="Date is only used as a reference. Jigsaur will try its best to match if available."
      name="date"
    >
      <DatePicker />
      {/* TODO: decide whether time is good
        <Form.Item<QueueType> noStyle name="preferredTime" rules={[required]}>
          <TimeInput />
        </Form.Item>
       */}
    </Form.Item>
  );
}
function DescriptionInput() {
  const descriptionTemplate = {
    explore:
      "A group of like-minded explorers who are curious, enthusiastic, and up for trying new things to embark on a journey to discover the lesser-known treasures of Sydney",
    museum:
      "A group of culture enthusiasts who share a passion for learning and discovering new and exciting museums, art galleries, and historical sites together",
    game: "A group of friends who love unwinding and having fun over a good game whether they are a board game buff, a card game enthusiast, or a gamer at heart!",
    run: "A group of like-minded people who are passionate about running, enjoy good company, and appreciate a mix of conversation and quiet time during their runs.",
    coffeeCatchUp:
      "A group of like-minded people who enjoy chatting, listening, and learning from others who want to slow down, unwind, and engage in meaningful conversations.",
  };
  const form = useFormInstance();
  const activity = Form.useWatch(
    "activityType",
    form,
  ) as keyof typeof descriptionTemplate;
  form.setFieldValue("description", descriptionTemplate[activity] || "");

  return (
    <Form.Item<PlanType>
      label="Description"
      help="Description for the group chat for the new group"
      name="description"
    >
      <Input.TextArea />
    </Form.Item>
  );
}

export default AdminPage;

import { auth } from "api/firebaseConfig";
import { UserProvider } from "api/user";
import Blog from "Blog";
import Loader from "component/Loader";
import CreateProfile from "CreateProfile";
import Deletion from "Deletion";
import FeedbackPage, { SuggestEventPage } from "FeedbackPage";
import { onAuthStateChanged, signOut } from "firebase/auth";
import LandingV2 from "LandingV2";
import NoMatchPage from "NoMatchPage";
import PlanPage from "PlanPage";
import ProfileDev from "ProfileDev";
import QuickStartPage from "QuickStartPage";
import { useContext, useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import Admin from "screens/Admin";
import SendPlanNotifications from "screens/Admin/SendPlanNotifications";
import { AuthContext } from "./api/auth";
import CreateQueue from "./CreateQueue";
import Home from "./HomePage";
import OldLandingPage from "./LandingPage";
import Login from "./Login";
import MessagePage from "./MessagePage";
import PrivacyPage from "./PrivacyPage";
import ProfilePage from "./ProfilePage";
import QueuePage from "./QueuePage";
import CreatePlan from "./screens/Admin/CreatePlan";
import TnCPage from "./TnC";

function ProfileWrapper() {
  const authUser = useContext(AuthContext);
  if (!authUser.user) {
    return (
      <Navigate to="/login" state={{ redirect: window.location.pathname }} />
    );
  }
  return (
    <UserProvider>
      <Outlet />
    </UserProvider>
  );
}

// eslint-disable-next-line
function IfAuth({
  loggedIn,
  loggedOut,
}: {
  loggedIn: JSX.Element;
  loggedOut: JSX.Element;
}): JSX.Element {
  const authUser = useContext(AuthContext);
  if (authUser?.user) {
    return loggedIn;
  }
  return loggedOut;
}

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    signOut(auth);
    return onAuthStateChanged(auth, (user) => {
      navigate("/");
    });
  }, [navigate]);

  return <Loader />;
}

const publicRoutes = [
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "/terms",
    element: <TnCPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/deletion",
    element: <Deletion />,
  },
  { element: <ProfileDev />, path: "profileDev" },
  {
    element: <OldLandingPage />,
    path: "/landing-old",
  },
  { element: <FeedbackPage />, path: "/contact" },
  { element: <Blog />, path: "/blog" },
  { element: <NoMatchPage />, path: "*" },
  // { element: <AboutPage />, path: "/about" },
  { element: <QuickStartPage />, path: "/quickstart/:activityId" },
  { element: <LandingV2 />, path: "/" },
];

const adminRoutes = [
  { element: <CreatePlan />, path: "createPlan" },
  { element: <SendPlanNotifications />, path: "notifications" },
];

const appRoutes = [
  { element: <CreateQueue />, path: "createQueue" },
  { element: <Home />, path: "/home" },
  { element: <MessagePage />, path: "hangout/:id" },
  { element: <ProfilePage />, path: "profile/:id?" },
  { element: <CreateProfile />, path: "/createProfile" },
  { element: <FeedbackPage auth />, path: "/feedback" },
  { element: <QueuePage />, path: "/q/:queueId/*" },
  { element: <SuggestEventPage />, path: "/suggest" },
  { element: <PlanPage />, path: "/p/:planId" },
  { element: <Admin />, path: "/admin", children: adminRoutes },
];

export const router = createBrowserRouter([
  ...publicRoutes,
  {
    element: <ProfileWrapper />,
    children: appRoutes,
  },
]);
